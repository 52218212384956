.dialog {
    @apply relative z-10;

    .background {
        @apply fixed inset-0 bg-black bg-opacity-25;
    }

    .contentWrapper {
        @apply fixed inset-0 overflow-y-auto;

        .content {
            @apply flex items-center justify-center p-4 text-center;

            .dialogPanel {
                @apply w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-primary shadow-xl rounded-2xl;
            
                .closeButton {
                    @apply flex justify-end
                }
            }
        }
    }
}