.wrapper {
    .title {
        @apply text-center font-bold;
    }

    .control {
        @apply flex items-center justify-center space-x-5;

        .number {
            @apply text-20 w-6 text-center;
        }

        .icon {
            @apply stroke-2;
        }
    }
}