.wrapper {
    @apply w-full max-w-2xl mx-auto rounded-md pb-5;

    .button {
        @apply w-full p-4 rounded-t-md border;

        .caption {
            @apply float-left w-11/12;
        }

        .iconWrapper {
            @apply float-right;

            .icon {
                @apply h-6 w-6 text-secondary transition-all duration-300;
    
                &.active {
                   @apply rotate-180 transform
                }
            }
        }
    }

    .panel {
       @apply pt-2 pb-2;
    }
}