.wrapper {
    .loading {
        @apply flex justify-center py-20;
    }

    .close {
        @apply text-right;
    }

    .title {
        @apply text-22 text-center font-bold text-secondary ml-10 mb-15;
    }

    .carouselWrapper {
        @apply mt-7 mb-10;
    }

    .productWrapper {
        @apply mt-7;

        .wrapper {
            @apply border rounded-md p-5 relative mt-10;
        
            .infoSection {
                @apply flex gap-10 pt-0 h-full;
        
                .img {
                    @apply w-24 rounded-md h-24 object-cover flex-none;
                }
    
                .infoWrapper {
                    @apply flex flex-col justify-between w-full;
    
                    .name {
                        @apply text-start text-15 line-clamp-2;
                    }
                    
                    .info {
                        @apply flex justify-between;
    
                        .text {
                            @apply text-12;
                        }
                    }
                }
            }
        
            .disclosureButton {
                @apply font-bold;
            }
        }
    }

    .noProductMessage {
        @apply text-center py-10;
    }

    .shopWrapper {
        @apply mt-7 text-center;

        .disclosureButton {
            @apply text-18 font-bold;
        }

        .info {
            @apply border rounded-b-md p-3 truncate;
        }
    }

    .buttonWrapper {
        @apply mt-7 grid grid-cols-2;
    }
}

.modal {
    @apply py-10;

    .title {
        @apply text-20 font-bold;
    }

    .plannedBox {
        @apply border p-10 rounded-md w-full flex items-start justify-between my-10;

        .fieldNames {
            @apply text-left text-15 space-y-10;
        }

        .values {
            @apply text-right text-15 space-y-10;
        }
    }
}