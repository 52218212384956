.wrapper {
    @apply text-center;

    .image {
        @apply mt-20 w-full h-auto;
    }

    .title {
        @apply text-24 font-bold mt-10 line-clamp-2;
    }

    .content {
        @apply text-15 mt-20 text-left;
    }

    .author {
        @apply mt-20 text-gray;
    }
}

.loading {
    @apply py-30 flex items-center justify-center;
}
