.wrapper {
    @apply bg-primary h-screen w-screen flex;

    .message {
        @apply m-auto text-24 text-center;

        a {
            @apply text-15 border-b;
        }
    }
}