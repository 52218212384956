.wrapper {
    @apply w-fit;

    .menu {
        @apply relative inline-block text-left;

        .buttonIcon {
            @apply w-6 h-6 text-secondary;
        }

        .items {
            @apply absolute right-0 z-50 origin-top-right rounded-md outline-none bg-secondary w-fit ring-0;

            .itemWrapper {
                @apply px-1 py-1;

                .item {
                    @apply flex items-center w-full gap-1 px-2 py-1 text-white rounded-md text-14 whitespace-nowrap;
                }
            }
        }
    }
}