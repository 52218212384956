.wrapper {
    @apply border rounded-md p-5 relative;
    .infoSection {
        @apply flex items-center gap-10;
        .img {
            @apply w-24 rounded-md h-full object-cover flex-none;
        }
        .textArea {
            @apply w-full;
            .storeName {
                @apply text-15 font-bold line-clamp-2;
                .quantity {
                    @apply text-12;
                }
            }
    
            .info {
                @apply text-12 text-gray mt-1 flex;
                .chartGood {
                    @apply ml-2;
                }
            }

            .savingWrapper {
                @apply flex items-center gap-10;
                .trending {
                    @apply flex items-center;        
                    .chartGood {
                        @apply text-green-500;
                    }
        
                    .chargNormal {
                        @apply text-blue;
                    }
        
                    .chartDanger {
                        @apply text-red;
                    }
        
                    p {
                        @apply ml-1 text-10;
                    }
                }
            }
        }

        .productNo {
            @apply flex items-center absolute right-1 bottom-0;
            .no {
                @apply whitespace-nowrap font-bold;
            }
            .check {
                @apply font-extraBold text-green-500 m-1;
            }
        }
    }

    .shop {
        @apply mt-2;

        .address {
            @apply text-center pt-10 pb-5;
        }
    }

    .disclosureButton {
        @apply font-bold;
    }
}