.wrapper {
    @apply w-full;

    .image {
        @apply flex mx-auto my-20;
    }

    .title {
        @apply font-bold text-20;
    }

    .priceWrapper {
        @apply flex justify-between mt-10 text-18;

        .save {
            @apply text-15 text-green-400;
        }
    }

    .title {
        @apply text-20 font-bold mt-30;
    }

    .listWrapper {
        @apply space-y-10 mt-20;
    }
}