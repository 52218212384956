.wrapper {
    @apply text-secondary;

    .header {
        @apply flex items-center justify-between;

        .buttonGroup {
            @apply flex gap-10 text-customBlue;

            .shoppingCart {
                @apply relative;

                .icon {
                    @apply text-white absolute top-6.9 left-10;
                }
            }
        }
    }

    .thumbnailWrapper {
        @apply w-full flex py-20;

        .img {
            @apply mx-auto w-auto h-auto rounded-md object-cover;
        }
    }
    .info {
        @apply mt-10;

        .name {
            @apply text-20 font-bold break-words;
        }

        .price {
            @apply text-15 font-bold text-gray;
        }

        .package {
            @apply text-15 font-bold text-gray;
        }
    }

    .storeInfo {
        @apply border p-10 rounded-md mt-20 flex justify-between items-end;

        .retailer_name {
            @apply text-start;
        }

        .trending {
            @apply flex items-center;

            .chartGood {
                @apply text-green-500;
            }

            .chargNormal {
                @apply text-blue;
            }

            .chartDanger {
                @apply text-red;
            }

            p {
                @apply mt-1 ml-10;
            }
        }
    }

    .description {
        @apply mt-30 space-y-20;

        .item {

            .title {
                @apply text-20 font-bold;
            }

            p {
                @apply text-14;
            }
        }
    }

    .otherOffer {
        @apply my-20 space-y-10;

        .title {
            @apply text-20 font-bold;
        }

        .shopInfoWrapper {
            @apply flex justify-between pr-2;
        }

        .address {
            @apply text-center truncate p-2;
        }
    }

    .addToCart {
        @apply mt-30 flex items-center justify-between gap-30;

        .buttonWrapper {
            @apply w-full;
        }
    }
}

.addShopping {
    .title {
        @apply text-18 font-bold;
    }

    .price {
        @apply mt-5;
    }

    .package {
        @apply mt-5;
    }

    .shoppingList {
        @apply mt-10 flex items-end justify-between border-y py-5;

        .shoppingListItem {
            @apply flex items-center gap-10;
        }
    }

    .button {
        @apply mt-20
    }
}