.wrapper {
    @apply p-10 shadow-lg rounded-md; 

    .image {
        @apply object-cover w-full h-auto;
    }

    .title {
        @apply mt-2 font-bold line-clamp-2;
    }

    .intro {
        @apply text-12 text-left line-clamp-2 break-words;
    }
}