.wrapper {
    @apply text-secondary;

    .titleWrapper {
        @apply flex items-end justify-between;

        .title {
            @apply text-18 font-bold;
        }

        .more {
            @apply text-15 font-medium border-b;
        }
    }


    .slider {
        @apply mt-13;

        .loading {
            @apply flex justify-center py-20;
        }

        .arrowIcon {
            @apply absolute top-1/2 -translate-y-1/2 z-5 cursor-pointer rounded-full fill-red text-white;
        }

        .noMessage {
            @apply text-center text-gray font-medium mb-20;
        }

        .sliderItem {
            @apply px-5 text-center;

            .img {
                @apply object-cover rounded-lg;
            }

            .name {
                @apply text-secondary text-14 mt-5 w-full truncate whitespace-nowrap;
            }
        }
    }
}