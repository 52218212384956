.wrapper {
    @apply text-center space-y-30;
    
    .loading {
        @apply flex justify-center py-25;
    }

    .blackLoading {
        @apply fixed inset-0 flex justify-center items-center bg-black bg-opacity-40 z-50;
    }
    
    .title {
        @apply font-bold text-20;
    }

    .listItem {
        @apply flex items-center justify-between mt-30;
    }

    .noShoppingMessage {
        @apply mt-30 mb-4;
    }
    
    .planWrapper {
        @apply text-center space-y-15;

        .titleWrapper {
            @apply font-bold text-20 mt-35;
        }
    
        .plan {
            @apply border px-10 py-15 rounded-md mt-10 relative mb-10 text-left cursor-pointer;
    
            .date {
                @apply text-15 text-gray flex items-center gap-2;
            }
    
            .name {
                @apply text-16 font-bold line-clamp-2;
            }
    
            .created {
                @apply text-15 text-gray;
            }

            .shopCount {
                @apply text-15 text-gray;
            }
            
            .trash {
                @apply absolute text-red right-2 top-1/2 -translate-y-1/2;
            }

            .messageTitle {
                @apply text-20 font-bold text-center;
            }

            .message {
                @apply text-center mt-10;
            }
        }
    }

}

.drawer {
    @apply py-20 space-y-20;

    .header {
        @apply flex items-center justify-between gap-20;

        .title {
            @apply text-20 font-bold;
        }

        .input {
            @apply w-full ring-0 outline-none p-2 rounded-md border-secondary border-2;
        }

        .buttonGroup {
            @apply flex gap-2;

            .button {
                @apply font-medium rounded-full text-center inline-flex items-center border-secondary border-2;
            }
        }
    }

    .noProductMessage {
        @apply text-center py-4 text-red;
    }
}

.modal {
    .alert {
        @apply text-center py-10;
    }

    .input {
        @apply mt-10;
    }

    .buttonGroup {
        @apply flex justify-between gap-10 w-full mt-10;

        button {
            @apply w-full;
        }
    }
}