.wrapper {
    @apply w-screen h-screen overflow-y-auto overflow-x-hidden bg-primary flex py-16 px-30;

    .toggleWrapper {
        @apply flex items-center text-white space-x-10 absolute top-6 right-30;

        .toggle {
            @apply relative inline-flex h-6 w-44 items-center rounded-full bg-secondary;

            &.active {
                @apply bg-secondary;
            }

            .toggleCircle {
                @apply inline-block h-4 w-4 transform rounded-full bg-white transition translate-x-1;

                &.active {
                    @apply translate-x-6;
                }
            }
        }
    }

    .content {
        @apply m-auto w-full max-w-3xl text-center h-full flex flex-col justify-between;

        .logo {
            @apply w-full text-center mx-auto flex items-center justify-start;

            .logoImage {
                @apply w-16 h-16;
            }

            .appName {
                @apply font-extraBold text-22 text-secondary ml-5;
            }
        }

        .body {
            @apply w-full text-left space-y-10;

            .title {
                @apply font-semiBold text-28 text-secondary;
            }

            .error {
                @apply flex text-left text-red text-15;
            }

            .message {
                @apply pt-8 text-center text-20;
            }
        }

        .buttonGroup { 
            @apply space-y-10 py-20 text-center;

            .error {
                @apply text-red text-15;
            }

            .message {
                @apply font-semiBold text-secondary;

                .link {
                    @apply font-bold border-b-2;
                }
            }
        }
    }
}