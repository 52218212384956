.wrapper {
    @apply border p-10 border-secondary rounded-md mt-20 mb-20;

    .infoSection {
        .title {
            @apply text-16;
        }

        .boldTitle {
            @apply text-16 mt-10;
        }

        .boldText {
            @apply font-medium text-red;
        }

        .infoBox {
            @apply flex justify-between mt-10;
        }

        .info {
            @apply text-16 mt-10 flex justify-between;
        }

        .buttonWrapper {
            @apply mt-3;
        }
    }
}