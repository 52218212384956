.wrapper {
    @apply text-left;

    .shoppinListItem {
        @apply flex items-center justify-between;

        .buttonWrapper {
            @apply flex items-center text-left gap-10;

            .shoppingCart {
                @apply relative flex;
    
                .shoppingCartIcon {
                    @apply text-customBlue;
                }
    
                .plusIcon {
                    @apply text-white absolute top-5.5 left-10;
                }
            }
    
            .info {
                .name {
                    @apply font-bold line-clamp-2;
                }
                
                .date {
                    @apply text-gray truncate text-15;
                }
            }
        }
    
        .actionButton {
            @apply relative;
        
            .panel {
                @apply hidden text-12 right-0 bg-secondary divide-y px-10 py-1 text-white z-10 rounded-md transition-all duration-300;
        
                &.show {
                    @apply block absolute;
                }

                div {
                    @apply px-10 py-1
                }
            }
        }
    }

}
