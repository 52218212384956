.wrapper {
    @apply max-w-2xl bg-secondary px-20 bg-opacity-80 flex items-center justify-between h-16 rounded-tr-2xl rounded-tl-2xl;

    .navItem {
        @apply text-center text-10 font-bold text-white w-[20%] whitespace-nowrap truncate;

        .icon {
            @apply mx-auto;
        }
    }
}