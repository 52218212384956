.wrapper {
    @apply text-secondary;

    .titleWrapper {
        @apply flex items-center gap-10;

        .title {
            @apply text-22 font-bold;
        }
    }

    .productWrapper {
        @apply mt-30;

        .productSearch {
            @apply py-20;
        
            .title {
                @apply mt-20 text-18 font-bold;
            }
        
            .productList {
                @apply mt-20 space-y-20;
        
                .item {
                    @apply w-full flex items-center justify-between rounded-md border border-gray relative;
        
                    .detail {
                        @apply flex items-center gap-10;
        
                        .img {
                            @apply w-24 h-24 object-cover rounded-md;
                        }
                        
        
                        .descritions {
                            @apply w-full;
                            max-width: 200px;
        
                            .description {
                                @apply text-12 whitespace-nowrap truncate;
                            }
        
                            .name {
                                @apply font-bold line-clamp-2 break-words text-12;
                            }
            
                            .price {
                                @apply whitespace-nowrap truncate text-12 text-gray font-bold;
                            }

                            .package {
                                @apply whitespace-nowrap truncate text-12 text-gray font-bold;
                            }

                            .savingWrapper {
                                @apply flex items-center gap-20;
        
                                .saving {
                                    @apply text-gray whitespace-nowrap truncate text-12;
                                }
            
                                .trending {
                                    @apply flex items-center;
                        
                                    .chartGood {
                                        @apply text-green-500;
                                    }
                        
                                    .chargNormal {
                                        @apply text-blue;
                                    }
                        
                                    .chartDanger {
                                        @apply text-red;
                                    }
                        
                                    p {
                                        @apply ml-1 text-10;
                                    }
                                }
                            }
            
                        }
                    }
        
                    .cart {
                        @apply absolute right-2 top-1/2 -translate-y-1/2;
                    }

                    .shoppingCart {
                        @apply relative flex;
            
                        .shoppingCartIcon {
                            @apply text-customBlue;
                        }
            
                        .plusIcon {
                            @apply text-white absolute top-5.5 left-10;
                        }
                    }
                }
            }
        
            .dropdowns {
                @apply grid grid-cols-2 gap-10;
            }
        }
        
        .noProduct {
            @apply py-40 text-center font-bold text-gray;
        }
        
        .loading {
            @apply py-40 flex justify-center items-center;
        }
    }
}