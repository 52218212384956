.wrapper {
    @apply m-10 h-fit flex items-center flex-col justify-center;
    
    .logo {
        @apply w-full h-full object-cover;
    }

    p {
        @apply text-12 w-full text-center font-bold mx-10;
    }
}


.default {
    @apply border p-10 mr-2 rounded-md text-center bg-none text-black;

    &.active {
        @apply bg-black text-white;
    }
}
