.wrapper {
    @apply w-screen h-screen bg-primary flex py-16 px-30;

    .toggleWrapper {
        @apply flex items-center text-white space-x-10 absolute top-6 right-30;

        .toggle {
            @apply relative inline-flex h-6 w-44 items-center rounded-full bg-secondary;

            &.active {
                @apply bg-secondary;
            }

            .toggleCircle {
                @apply inline-block h-4 w-4 transform rounded-full bg-white transition translate-x-1;

                &.active {
                    @apply translate-x-6;
                }
            }
        }
    }

    .content {
        @apply m-auto w-full max-w-3xl text-center h-full flex flex-col justify-between;

        .logo {
            @apply text-center mx-auto flex justify-center items-center flex-col;

            .logoImage {
                @apply w-48 h-48;
            }

            .welcome {
                @apply font-semiBold text-28 text-secondary;
            }

            .appName {
                @apply font-extraBold text-35 text-secondary;
            }
        }

        .inputGroup {
            @apply space-y-10;

            .error {
                @apply flex text-left text-red text-15;
            }
        }

        .buttonGroup { 
            @apply space-y-10 py-20;
            
            .error {
                @apply text-red text-15;
            }


            .message {
                @apply font-semiBold text-secondary;

                .link {
                    @apply font-bold border-b-2;
                }
            }
        }
    }
}

.zoneWrapper {
    @apply w-screen h-screen bg-primary flex flex-col py-16 px-30;

    .toggleWrapper {
        @apply flex items-center text-white space-x-10 absolute top-6 right-30;

        .toggle {
            @apply relative inline-flex h-6 w-44 items-center rounded-full bg-secondary;

            &.active {
                @apply bg-secondary;
            }

            .toggleCircle {
                @apply inline-block h-4 w-4 transform rounded-full bg-white transition translate-x-1;

                &.active {
                    @apply translate-x-6;
                }
            }
        }
    }

    .logo {
        @apply text-center mx-auto flex justify-center items-center flex-col;

        .logoImage {
            @apply w-48 h-48;
        }

        .welcome {
            @apply font-semiBold text-28 text-secondary;
        }

        .appName {
            @apply font-extraBold text-35 text-secondary;
        }
    }

    .message {
        @apply mt-60;

        .title {
            @apply font-semiBold text-18 text-center;
        }
        
        .description {
            @apply my-6 break-words;
        }
    }
}