@import url('https://fonts.cdnfonts.com/css/poppins');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #f0ead6;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-next {
  display: none !important;
}

.slick-prev {
  display: none !important;
}

.tag_icon_pedestrian {
  height: 0; /* Avoid a "ghost" clickable area */
}

.iconContent {
  background-color: yellow;
  border: 1px solid black;
  transform: translateY(-100%); /* Move your icon its full content height */
}