.loading {
    @apply fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 z-50;
}

.addShopping {
    .title {
        @apply text-18 font-bold;
    }

    .price {
        @apply mt-5;
    }

    .package {
        @apply mt-5;
    }

    .shoppingList {
        @apply mt-10 flex items-end justify-between border-b border-gray py-5;

        .shoppingListItem {
            @apply flex items-center gap-10;
            
            .shoppingCart {
                @apply relative flex;
    
                .shoppingCartIcon {
                    @apply text-customBlue;
                }
    
                .plusIcon {
                    @apply text-white absolute top-1 left-2;
                }
            }
        }
    }

    .shoppingPlan {
        @apply mt-12 flex items-end justify-between border-b border-gray py-5;

        .shoppingListItem {
            @apply flex items-center gap-10;
            
            .shoppingCart {
                @apply relative flex;
    
                .shoppingCartIcon {
                    @apply text-customBlue;
                }
    
                .plusIcon {
                    @apply text-white absolute top-1 left-2;
                }
            }
        }

        .gty {
            .icon {
                @apply stroke-2;
            }
        }
    }

    .button {
        @apply mt-20
    }
}