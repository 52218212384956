.wrapper {
    @apply fixed inset-0 bg-primary z-50;

    .contentWrapper {
        @apply relative w-full h-full bg-secondary bg-opacity-40;
        
        .content {
            @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
        }
    }

}