.wrapper {
    @apply w-full;

    .label {
        @apply text-15 text-secondary;
    }
    
    .content {
        @apply relative;

        .button {
            @apply relative w-full cursor-default rounded-md bg-white py-2.5 pl-3 pr-10 text-left ring-0 outline-none border-2 border-secondary;

            .icon {
                @apply pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2;
            }
        }

        .options {
            @apply absolute mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 shadow-lg z-30;

            .option {
                @apply relative cursor-default select-none py-2 pl-45 pr-4;

                &.active {
                    @apply bg-amber-100 text-amber-900;
                }

                &.disable {
                    @apply text-secondary;
                }

                .checkIconWrapper {
                    @apply absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600;
                }
            }
        }
    }
}