.wrapper {
    @apply flex items-center;

    .checkWrapper {
        @apply w-fit text-white;

        .checkbox {
            @apply w-8 h-8 appearance-none rounded cursor-pointer flex-none border-2 border-secondary relative;

            &:checked {
                @apply bg-secondary;
            }

            &::after {
                content: "";
                @apply absolute left-2 bottom-1.5 w-13 h-20 border-primary border-solid transform rotate-45;
                border-width: 0 4px 4px 0;
            }
        }
    }

    .label {
        @apply ml-10 w-full;
    }
}