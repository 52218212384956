.wrapper {
    @apply mt-20;
}

.subMenuItem {
    @apply flex items-center justify-between text-secondary py-10;

    .labelWrapper {
        @apply flex items-center;

        .label {
            @apply text-18 text-secondary ml-10 font-semiBold;
        }
    }

}