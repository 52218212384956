.wrapper {

    .loading {
        @apply flex justify-center py-40;
    }

    .solutionWrapper {
        @apply border p-10 border-secondary rounded-md mt-20;

        .info {
            @apply font-medium text-18 mt-10;
        }
    }

    .noSolutionMessage {
        @apply py-30 text-center;
    }
}