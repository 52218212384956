.wrapper {
    @apply shadow-lg h-full mx-1 rounded-md relative;

    .imageWrapper {

        .image {
            @apply rounded-tr-md rounded-tl-md object-cover tablet:h-140 h-90 w-full;
        }
    }

    .infoPanel {
        @apply p-5 bg-white rounded-bl-md rounded-br-md;
        
        .nameWrapper {
            @apply h-30;

            .name {
                @apply text-10 break-words line-clamp-2 font-bold;
            }
        }

        .address {
            @apply text-10 break-words line-clamp-1 mt-2 text-gray;
        }

        .priceWrapper {
            @apply flex items-center justify-between;

            .trending {
                @apply flex items-center;
    
                .chartGood {
                    @apply text-green-500;
                }
    
                .chargNormal {
                    @apply text-blue;
                }
    
                .chartDanger {
                    @apply text-red;
                }
    
                p {
                    @apply ml-1 text-10;
                }
            }

            .price {
                @apply text-13 break-words line-clamp-1 text-gray font-bold;
            }

            .package {
                @apply text-13 break-words line-clamp-1 text-gray font-bold;
            }
        }
    }

    .actionWrapper {
        @apply absolute top-0 right-0 flex items-center gap-5 text-secondary px-5 bg-white shadow-lg rounded-bl-md border-l border-b border-gray;
        
        .shoppingCart {
            @apply relative flex;

            .shoppingCartIcon {
                @apply text-customBlue;
            }

            .plusIcon {
                @apply text-white absolute top-1 left-2;
            }
        }
    }
}