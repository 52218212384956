.wrapper {
    @apply relative w-full h-full;

    .map {
        @apply z-0 w-full h-full;
    }

    .control {
        @apply absolute z-10 top-2 right-1 p-2 bg-white rounded-md w-min;

        .label {
            @apply text-12
        }

        .input {
            @apply w-full;
        }

        .buttonWrapper {
            @apply flex gap-5 items-center;

            .button {
                @apply text-12 bg-secondary text-white p-1 rounded-md whitespace-nowrap max-w-[140px] truncate;
            }
        }

        .description {
            @apply text-12 mt-10 break-words;
        }
    }

    .table {
        @apply absolute bottom-0 right-0 p-2 bg-secondary bg-opacity-80 text-white rounded-md text-12;

        .item {
            @apply flex items-center gap-5;

            .circle {
                @apply w-3 h-3 rounded-full;
            }
        }
    }
}

.modalWrapper {
    @apply space-y-5 grid grid-cols-2;

    .listItem {
        @apply flex items-center;
    }
}
