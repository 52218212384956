.wrapper {
    @apply flex items-center justify-center h-full;

    .content {
        @apply m-auto text-center font-bold;

        .button {
            @apply mt-30;
        }
    }
}