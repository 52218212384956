.wrapper {
    @apply bg-primary w-screen h-screen ;
    
    .content {
        @apply relative w-full h-full max-w-2xl mx-auto;

        .header {
            @apply absolute w-full top-0 z-10 bg-secondary bg-opacity-80 py-2 px-20 flex items-center justify-between;

            .logoSection {
                @apply flex items-center;

                .logo {
                    @apply w-12 h-12;
                }

                .name {
                    @apply text-white text-20 font-semiBold ml-10;
                }
            }

            .search {
                @apply flex items-center text-white space-x-10;

                .toggle {
                    @apply relative inline-flex h-6 w-44 items-center rounded-full bg-gray;

                    &.active {
                        @apply bg-lightGray;
                    }

                    .toggleCircle {
                        @apply inline-block h-4 w-4 transform rounded-full bg-secondary transition translate-x-1;

                        &.active {
                            @apply translate-x-6;
                        }
                    }
                }
            }
    

        }

        .children {
            @apply px-20 py-80 h-full overflow-y-auto overflow-x-hidden;
        }
    
        .footer {
            @apply fixed bottom-0 w-full z-10;
        }
    }

}