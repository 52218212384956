.wrapper {
    @apply text-left;

    .label {
        @apply text-15 mb-5 text-secondary;
    }

    .input {
        @apply w-full ring-0 outline-none p-10 rounded-md border-secondary border-2;
    }
}
