.wrapper {
    @apply fixed inset-0 overflow-hidden z-10;

    .content {
        @apply absolute inset-0 overflow-hidden;

        .overlay {
            @apply absolute inset-0
        }

        .dialog {
            @apply fixed inset-y-0 right-0 max-w-full flex;

            .contentWrapper {
                @apply w-screen max-w-md;

                .drawerBody {
                    @apply h-full flex flex-col py-6 bg-primary shadow-xl overflow-y-scroll;

                    .drawerHeader {
                        @apply flex items-start justify-between px-4 tablet:px-6;

                        .title {
                            @apply text-20 font-medium text-secondary;
                        }

                        .button {
                            @apply text-secondary outline-none ring-0;
                        }
                    }

                    .body {
                        @apply mt-6 relative flex-1 px-4 tablet:px-6;

                        .bodyContent {
                            @apply absolute inset-0 px-4 tablet:px-6;
                        }
                    }
                }
            }
        }
    }
}