.wrapper {
    @apply text-center;

    .loading {
        @apply py-30 flex items-center justify-center;
    }

    .blogs {
        @apply grid grid-cols-2 gap-10 mt-20;
    }
}