.wrapper {
    @apply text-secondary;

    .titleWrapper {
        @apply flex items-center gap-10;

        .title {
            @apply text-22 font-bold;
        }
    }
    
    .productList {
        @apply grid grid-cols-2 gap-20 mt-30;
    }

    .noProduct {
        @apply py-40 text-center font-bold text-gray;
    }
}

.loading {
    @apply py-30 flex items-center justify-center w-full;
}