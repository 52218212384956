.wrapper {
    .alert {
        @apply border rounded-md p-10 mt-20;

        .buttonWrapper {
            @apply flex gap-20 items-center justify-center mt-10;

            button {
                @apply w-32;
            }
        }
    }

    .stores {
        @apply my-30 space-y-20;

        .titleWrapper {
            @apply flex items-center justify-between;

            .title {
                @apply text-18 font-bold;
            }
        }

        .cardWrapper {
            @apply border rounded-md p-5 relative flex justify-start items-center text-center gap-3;

            .img {
                @apply w-24 rounded-md h-full object-cover flex-none;
            }

            .info {
                @apply h-full;

                .name {
                    @apply text-start m-auto text-18 font-semiBold line-clamp-2;
                }
            }
        }
    }

}